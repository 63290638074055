import { kebabCase, isArray } from 'lodash-es'

const getItem = (data: any, mainStore: any) => {
  if (data?.__component === 'shared.link-single-type') {
    return {...data, uid: kebabCase(data?.page)}
  } else if (data?.__component === 'shared.link-collection-type') {
    if (getFirstObject(data) === 'urbanVisionEntry' && getFirstObject(data)?.biennialEntry) {
      return {
        '__component': data?.__component,
        ...getFirstObject(data),
        uid: 'urban-vision-page'
      }
    }
    else {
      return {
        '__component': data?.__component,
        ...getFirstObject(data),
        uid: kebabCase(getFirstObject(data)?.uid)
      }
    }
  }
}

const getLink = (data: any) => {
  if (data?.__component === 'shared.link-single-type') {
    return {
      routeObject: {
        name: getStrapiRouteReference(data.uid)?.name
      }
    }
  } else if (data?.__component === 'shared.link-collection-type') {
    if (data?.uid === 'urbanVisionEntry' && data?.biennialEntry) {
      return {
        routeObject: {
          name: getStrapiRouteReference(data.uid)?.name,
          params: {
            slug: data?.biennialEntry?.slug
          }
        }
      }
    } else {
      return {
        routeObject: {
          name: getStrapiRouteReference(data.uid)?.name,
          params: {
            slug: data?.slug
          }
        }
      }
    }
  }
}

export const getNavigationItem = (data: any) => {
  const mainStore = useMainStore()

  const item = getItem(data, mainStore)
  const link = getLink(item) ? { link: getLink(item) } : {}

  return {
    ...item,
    ...link
  }
}
